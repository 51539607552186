<template>
  <div class="d-flex flex-row align-center">
    <itemCard @click="showAction" :data="computedData" :key="componentKey" :qrcodeLink="data.qr_code_url" :status="data.status" :arrivalAt="data.arrival_at" :orderNo="data.order_no" :isDraggable="isDraggable"/>
    <v-btn
      v-show="isDraggable"
      eagle-order-selector="draggable-button"
      class="order-draggable"
      icon
    >
      <v-icon>fa fa-bars</v-icon>
    </v-btn>
  </div>
  
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import listRow from '@/components/list/mixins/listRow';
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [listItem, listRow],
  components: {
    itemCard: () => import("@/modules/base/components/deliveryMan/itemCard.vue"),
  },
  props: {
    isDraggable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      useData: true,
      componentKey: 0
    }
  },
  computed: {
    ...mapState({
      
    }),
    ...mapGetters({
      providerId: `member/providerId`,
    }),
    computedData() {
      return [
        {
          text: "",
          value: this.data.store_name,
          phones: this.data.phones
        },
        {
          text: "",
          value: this.data.address,

        },
        {
          text: "代收貨款",
          value: `$${this.data.charge}`,
        },
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
      ];
    },
    listActions() {
      console.log('listActions:', this.$store.getters[`list/${this.listKey}/actions`] )
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
  },
  methods: {
    showAction(){
      console.log(this.listActions)
    },
  },
}
</script>

<style lang="scss">
.checkbox {
  visibility: hidden;
}
</style>